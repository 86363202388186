import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './resources/translation/en.json'
import translationZH from './resources/translation/zh.json'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        preload: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: translationEN
            },
            zh: {
                translation: translationZH
            }
        }
    })

export default i18n