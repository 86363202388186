import { Box, AppBar, Toolbar, IconButton, Badge, Button, SwipeableDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Menu, MenuItem, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip, Stack, ToggleButtonGroup, ToggleButton } from "@mui/material"
import {
    Chat,
    Payment,
    PeopleOutline,
    MoneyOutlined,
    AssignmentReturnOutlined,
    ReceiptOutlined,
    ArrowDropDown,
    Logout,
    AttachMoney,
    CreditCardOutlined,
    DocumentScanner,
    AccountBalanceWalletOutlined,
    AccountBalanceOutlined,
    PaymentOutlined,
    ContactPhoneOutlined,
    PeopleOutlineOutlined,
    AddIcCall,
    PhonelinkSetupOutlined,
    SmsOutlined,
    DomainVerification,
    WebAssetOutlined,
    DisplaySettingsOutlined,
    PhotoAlbumOutlined,
    GamepadOutlined,
    Security,
    ExpandMore,
    Quickreply,
    Language,
    ImportExportOutlined,
    GamesOutlined,
    VideogameAsset,
    DarkMode,
} from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { useContext, useEffect, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import Constants, { availableLanguages, languages, keys_language_code, keys_dark_mode, roleBOHelper } from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import { useNavigate } from "react-router-dom"
import newMessageTone from '../../resources/sound/newMessageTone.mp3'
import newTransactionTone from '../../resources/sound/newTransactionTone.mp3'
import { useFind } from 'figbird'
import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import useScreenType from "react-screentype-hook"
import Utils from "../../utils/utils"
import { useTranslation } from "react-i18next";

function AppTopBar() {
    const navigate = useNavigate()
    const screenType = useScreenType()

    //ENUM
    const MenuType = {
        dashboard: 'dashboard',
        liveChat: 'liveChat',
        cashInOut: 'cashInOut',
        customerList: 'customerList',
        commissionPay: 'commissionPay',
        rebatePay: 'rebatePay',
        bonusSet: 'bonusSet',
        rebateSet: 'rebateSet',
        commissionSet: 'commissionSet',
        spinAndWinSet: 'spinAndWinSet',
        depositWithdrawReport: 'depositWithdrawReport',
        bankReport: 'bankReport',
        bonusReport: 'bonusReport',
        rebateReport: 'rebateReport',
        commissionReport: 'commissionReport',
        manualForfeitReport: 'manualForfeitReport',
        gameReport: 'gameReport',
        customerReport: 'customerReport',
        staffReport: 'staffReport',
        transactionsSettings: 'transactionsSettings',
        bankAccountSettings: 'bankAccountSettings',
        paymentGatewaySettings: 'paymentGatewaySettings',
        contactUsSettings: 'contactUsSettings',
        memberSettings: 'memberSettings',
        phoneCodesSettings: 'phoneCodesSettings',
        botSettings: 'botSettings',
        whatsAppBotsSettings: 'whatsAppBotsSettings',
        smsSettings: 'smsSettings',
        domainsSettings: 'domainsSettings',
        usersRoleSettings: 'usersRoleSettings',
        languageSettings: 'languageSettings',
        themesCustomize: 'themesCustomize',
        displayCustomize: 'displayCustomize',
        bannerCustomize: 'bannerCustomize',
        gameCustomize: 'gameCustomize',
        gameCategoryCustomize: 'gameCategoryCustomize',
        changePassword: 'changePassword',
        darkMode: 'darkMode',
        logout: 'logout',
        chatTemplates: 'chatTemplates',
        export: 'export',
    }

    //Context
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { data, error } = useFind('transactions', {
        query: {
            company: userInfo?.company?._id,
            type: {
                '$in': [
                    1, 2,
                ]
            },
            transferMethod: {
                '$in': [
                    1, 2, 3, null,
                ],
            },
            status: {
                '$in': [
                    Constants.transactionStatusPending,
                ]
            },
        },
        allPages: true,
        realtime: 'refetch'
    })
    const companyInfo = useCompanyInfo()
    const { t, i18n } = useTranslation()


    //State
    const [newTransactionPlayer, setNewTransactionPlayer] = useState(new Audio(newTransactionTone))
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [showNewMessageDot, setShowNewMessageDot] = useState(false)
    const [showNewTransactionDot, setShowNewTransactionDot] = useState(false)
    const [darkMode, setDarkMode] = useState(Utils.shared.retrieveLocalStorage(keys_dark_mode) ?? 'off')
    const [sideMenu, setSideMenu] = useState([
        // {
        //   'title': 'DASHBOARD',
        //   'icon': Icons.dashboard_outlined,
        //   'isExpandable': false,
        //   'isExpanded': false,
        //   'excludeFromRoles': [6, 7],
        //   'type': MenuType.dashboard,
        // },
        {
            'title': 'DRAWER_LIVE_CHAT_C',
            'icon': <Badge badgeContent={1} invisible={showNewMessageDot ? false : true} variant='dot' color="error">
                <Chat />
            </Badge>,
            'isExpandable': false,
            'isExpanded': false,
            'excludeFromRoles': [
                Constants.roleBOAgent
            ],
            'type': MenuType.liveChat,
        },
        {
            'title': 'DRAWER_CASH_IN_CASH_OUT_C',
            'icon': <Badge badgeContent={1} invisible={showNewTransactionDot ? false : true} variant='dot' color="error">
                <Payment />
            </Badge>,
            'isExpandable': false,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOAgent, Constants.roleBOCS],
            'type': MenuType.cashInOut,
        },
        {
            'title': 'DRAWER_CUSTOMER_LIST_C',
            'icon': <PeopleOutline />,
            'isExpandable': false,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOAgent],
            'type': MenuType.customerList,
        },
        {
            'title': 'DRAWER_COMMISSION_PAY_C',
            'icon': <MoneyOutlined />,
            'isExpandable': false,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOAgent, Constants.roleBOCS],
            'type': MenuType.commissionPay,
        },
        {
            'title': 'DRAWER_REBATE_PAY_C',
            'icon': <AssignmentReturnOutlined />,
            'isExpandable': false,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOAgent, Constants.roleBOCS],
            'type': MenuType.rebatePay,
        },
        {
            'title': 'DRAWER_EXPORT_C',
            'icon': <ImportExportOutlined />,
            'isExpandable': false,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOAgent, Constants.roleBOCS],
            'type': MenuType.export,
        },
        {
            'title': 'DRAWER_PROMOTION_C',
            'icon': null,
            'excludeFromRoles': [Constants.roleBOStaff, Constants.roleBOAgent, Constants.roleBOCS, roleBOHelper],
            'isExpandable': true,
            'isExpanded': false,
            'expandedItems': [
                {
                    'title': 'DRAWER_BONUS_SET_C',
                    'icon': <AttachMoney />,
                    'type': MenuType.bonusSet,
                },
                {
                    'title': 'DRAWER_REBATE_SET_C',
                    'icon': <AssignmentReturnOutlined />,
                    'type': MenuType.rebateSet,
                },
                {
                    'title': 'DRAWER_COMMISSION_SET_C',
                    'icon': <CreditCardOutlined />,
                    'type': MenuType.commissionSet,
                },
                // {
                //     'title': 'SPIN & WIN (BETA)',
                //     'icon': <AttractionsOutlined />,
                //     'type': MenuType.spinAndWinSet,
                // }
            ]
        },
        {
            'title': 'DRAWER_REPORT_C',
            'icon': null,
            'isExpandable': true,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOCS],
            'expandedItems': [
                {
                    'title': 'DRAWER_DEPOSIT_WITHDRAW_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.depositWithdrawReport,
                },
                {
                    'title': 'DRAWER_BANK_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.bankReport,
                    'excludeFromRoles': [Constants.roleBOAgent],
                },
                {
                    'title': 'DRAWER_BONUS_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.bonusReport,
                    'excludeFromRoles': [Constants.roleBOAgent],
                },
                {
                    'title': 'DRAWER_REBATE_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.rebateReport,
                    'excludeFromRoles': [Constants.roleBOAgent],
                },
                {
                    'title': 'DRAWER_COMMISSION_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.commissionReport,
                },
                {
                    'title': 'DRAWER_MANUAL_FORFEIT_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.manualForfeitReport,
                    'excludeFromRoles': [Constants.roleBOAgent],
                },
                {
                    'title': 'DRAWER_GAME_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.gameReport,
                    'excludeFromRoles': [Constants.roleBOAgent],
                },
                {
                    'title': 'DRAWER_CUSTOMER_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.customerReport,
                },
                {
                    'title': 'DRAWER_STAFF_C',
                    'icon': <DocumentScanner />,
                    'type': MenuType.staffReport,
                    'excludeFromRoles': [Constants.roleBOAgent],
                }
            ]
        },
        {
            'title': 'DRAWER_SETTINGS_C',
            'icon': null,
            'isExpandable': true,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOAgent, Constants.roleBOCS],
            'expandedItems': [
                {
                    'title': 'DRAWER_TRANSACTIONS_C',
                    'icon': <AccountBalanceWalletOutlined />,
                    'type': MenuType.transactionsSettings,
                    'excludeFromRoles': [Constants.roleBOStaff, roleBOHelper],
                },
                {
                    'title': 'DRAWER_BANK_ACCOUNT_C',
                    'icon': <AccountBalanceOutlined />,
                    'type': MenuType.bankAccountSettings,
                },
                {
                    'title': 'DRAWER_PAYMENT_GATEWAY_C',
                    'icon': <PaymentOutlined />,
                    'type': MenuType.paymentGatewaySettings,
                    'excludeFromRoles': [Constants.roleBOStaff, roleBOHelper],
                },
                {
                    'title': 'DRAWER_CONTACT_US_C',
                    'icon': <ContactPhoneOutlined />,
                    'type': MenuType.contactUsSettings,
                    'excludeFromRoles': [Constants.roleBOStaff, roleBOHelper],
                },
                {
                    'title': 'DRAWER_MEMBER_C',
                    'icon': <PeopleOutlineOutlined />,
                    'type': MenuType.memberSettings,
                    'excludeFromRoles': [Constants.roleBOStaff, roleBOHelper],
                },
                {
                    'title': 'DRAWER_PHONE_CODES_C',
                    'icon': <AddIcCall />,
                    'type': MenuType.phoneCodesSettings,
                    'excludeFromRoles': [Constants.roleBOStaff, roleBOHelper],
                },
                // {
                //     'title': 'WHATSAPP BOT',
                //     'icon': <PhonelinkSetupOutlined />,
                //     'type': MenuType.whatsAppBotsSettings,
                // },
                {
                    'title': 'DRAWER_BOTS_C',
                    'icon': <PhonelinkSetupOutlined />,
                    'type': MenuType.botSettings,
                },
                {
                    'title': 'DRAWER_SMS_C',
                    'icon': <SmsOutlined />,
                    'type': MenuType.smsSettings,
                },
                {
                    'title': 'DRAWER_DOMAINS_C',
                    'icon': <DomainVerification />,
                    'type': MenuType.domainsSettings,
                    'excludeFromRoles': [Constants.roleBOStaff, roleBOHelper],
                },
                {
                    'title': 'DRAWER_CHAT_TEMPLATES_C',
                    'icon': <Quickreply />,
                    'type': MenuType.chatTemplates,
                    'excludeFromRoles': [Constants.roleBOAgent],
                },
                {
                    'title': 'DRAWER_USERS_ROLE_C',
                    'icon': <PeopleOutline />,
                    'type': MenuType.usersRoleSettings,
                    'excludeFromRoles': [Constants.roleBOStaff, roleBOHelper],
                },
                {
                    'title': 'DRAWER_LANGUAGE_C',
                    'icon': <Language />,
                    'type': MenuType.languageSettings,
                    'excludeFromRoles': [Constants.roleBOCS],
                },
            ]
        },
        {
            'title': 'DRAWER_CUSTOMIZE_C',
            'icon': null,
            'isExpandable': true,
            'isExpanded': false,
            'excludeFromRoles': [Constants.roleBOAgent, Constants.roleBOCS],
            'expandedItems': [
                {
                    'title': 'DRAWER_THEMES_C',
                    'icon': <WebAssetOutlined />,
                    'type': MenuType.themesCustomize,
                },
                {
                    'title': 'DRAWER_DISPLAY_C',
                    'icon': <DisplaySettingsOutlined />,
                    'type': MenuType.displayCustomize,
                },
                {
                    'title': 'DRAWER_BANNER_C',
                    'icon': <PhotoAlbumOutlined />,
                    'type': MenuType.bannerCustomize,
                },
                {
                    'title': 'DRAWER_GAME_CATEGORY_C',
                    'icon': <VideogameAsset />,
                    'type': MenuType.gameCategoryCustomize,
                },
                {
                    'title': 'DRAWER_GAME_C',
                    'icon': <GamepadOutlined />,
                    'type': MenuType.gameCustomize,
                },
            ]
        },
        {
            'title': 'DRAWER_USER_PROFILE_C',
            'icon': null,
            'isExpandable': true,
            'isExpanded': true,
            'expandedItems': [
                {
                    'title': 'DRAWER_DARK_MODE_C',
                    'type': MenuType.darkMode,
                },
                {
                    'title': 'DRAWER_CHANGE_PASSWORD_C',
                    'icon': <Security />,
                    'type': MenuType.changePassword,
                },
                {
                    'title': 'DRAWER_LOGOUT_C',
                    'icon': <Logout />,
                    'type': MenuType.logout,
                },
            ]
        },
    ])
    const [languageCode, setLanguageCode] = useState(Utils.shared.getDefaultLanguage({ availableLanguages: availableLanguages, i18n: i18n }))

    //For User Settings Menu
    const [anchorEl, setAnchorEl] = useState(null)
    const isSettingsMenuOpen = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    //For Change Language Menu
    const [anchorEl2, setAnchorEl2] = useState(null)
    const isSettingsMenuOpen2 = Boolean(anchorEl2)
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget)
    }
    const handleClose2 = () => {
        setAnchorEl2(null)
    }


    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            (event.type === 'keydown') &&
            ((event.key === 'Tab') || (event.key === 'Shift'))
        ) {
            return
        }

        setDrawerOpen(open)
    }

    //COMPONENT METHODS
    //Localization
    let selectedLanguage = languages.find((e) => e.code === languageCode) ?? languages[0]
    const getLanguageMenu = () => {
        //First get active languages
        let activeLanguages = availableLanguages.length > 0 ? languages.filter((lang) => {
            let index = availableLanguages.findIndex((al) => al === lang.code)

            return index > -1
        }) : languages

        return activeLanguages.map((e, index) => {
            let selected = e.code === selectedLanguage.code

            return (
                <MenuItem key={`languageMenu${index}`} selected={selected} style={{ color: 'black' }} onClick={() => {
                    setLanguageCode(e.code)
                }} >
                    <ListItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/images/flags/${e.icon}`} alt='language' height='30px' width='auto' loading="lazy">
                        </img>
                    </ListItemIcon>
                    {e.title}
                </MenuItem>
            )
        })
    }

    const getPageTitle = () => {
        var pageTitle
        let currentPath = window.location.pathname
        if (currentPath.indexOf('/liveChat') > -1) {
            pageTitle = `${t('PAGE_TITLE_LIVE_CHAT_C')}`
        } else if (currentPath.indexOf('/bankTransaction') > -1) {
            pageTitle = `${t('PAGE_TITLE_BANK_TRANSACTION_C')}`
        } else if (currentPath.indexOf('/customers') > -1) {
            pageTitle = `${t('PAGE_TITLE_CUSTOMERS_C')}`
        } else if (currentPath.indexOf('/report/bank') > -1) {
            pageTitle = `${t('PAGE_TITLE_BANK_REPORT_C')}`
        } else if (currentPath.indexOf('/report/bonus') > -1) {
            pageTitle = `${t('PAGE_TITLE_BONUS_REPORT_C')}`
        } else if (currentPath.indexOf('/report/rebate') > -1) {
            pageTitle = `${t('PAGE_TITLE_REBATE_REPORT_C')}`
        } else if (currentPath.indexOf('/report/commission') > -1) {
            pageTitle = `${t('PAGE_TITLE_COMMISSION_REPORT_C')}`
        } else if (currentPath.indexOf('/report/manualForfeit') > -1) {
            pageTitle = `${t('PAGE_TITLE_MANUAL_FORFEIT_REPORT_C')}`
        } else if (currentPath.indexOf('/report/staff') > -1) {
            pageTitle = `${t('PAGE_TITLE_STAFF_REPORT_C')}`
        } else if (currentPath.indexOf('/report/depositWithdraw') > -1) {
            pageTitle = `${t('PAGE_TITLE_DEPOSIT_WITHDRAWAL_REPORT_C')}`
        } else if (currentPath.indexOf('/report/winLoss') > -1) {
            pageTitle = `${t('PAGE_TITLE_WIN_LOSS_REPORT_C')}`
        } else if (currentPath.indexOf('/report/game') > -1) {
            pageTitle = `${t('PAGE_TITLE_GAME_REPORT_C')}`
        } else if (currentPath.indexOf('/report/customer') > -1) {
            pageTitle = `${t('PAGE_TITLE_CUSTOMER_REPORT_C')}`
        } else if (currentPath.indexOf('/settings/transaction') > -1) {
            pageTitle = `${t('PAGE_TITLE_TRANSACTION_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/bank') > -1) {
            pageTitle = `${t('PAGE_TITLE_BANK_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/paymentGateway') > -1) {
            pageTitle = `${t('PAGE_TITLE_PAYMENT_GATEWAY_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/contact') > -1) {
            pageTitle = `${t('PAGE_TITLE_CONTACT_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/member') > -1) {
            pageTitle = `${t('PAGE_TITLE_MEMBER_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/phoneCode') > -1) {
            pageTitle = `${t('PAGE_TITLE_PHONE_CODE_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/sms/history') > -1) {
            pageTitle = `${t('PAGE_TITLE_SMS_HISTORY_C')}`
        } else if (currentPath.indexOf('/settings/sms') > -1) {
            pageTitle = `${t('PAGE_TITLE_SMS_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/domain') > -1) {
            pageTitle = `${t('PAGE_TITLE_DOMAIN_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/user') > -1) {
            pageTitle = `${t('PAGE_TITLE_USER_ROLE_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/language') > -1) {
            pageTitle = `${t('PAGE_TITLE_LANGUAGE_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/whatsapp') > -1) {
            pageTitle = `${t('PAGE_TITLE_WHATSAPP_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/bots') > -1) {
            pageTitle = `${t('PAGE_TITLE_BOT_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/chatTemplates') > -1) {
            pageTitle = `${t('PAGE_TITLE_CHAT_TEMPLATES_C')}`
        } else if (currentPath.indexOf('/customize/theme') > -1) {
            pageTitle = `${t('PAGE_TITLE_CUSTOMIZE_THEME_C')}`
        } else if (currentPath.indexOf('/customize/display') > -1) {
            pageTitle = `${t('PAGE_TITLE_CUSTOMIZE_DISPLAY_C')}`
        } else if (currentPath.indexOf('/customize/banner') > -1) {
            pageTitle = `${t('PAGE_TITLE_CUSTOMIZE_BANNER_C')}`
        } else if (currentPath.indexOf('/customize/gameCategory') > -1) {
            pageTitle = `${t('PAGE_TITLE_CUSTOMIZE_GAME_CATEGORY_C')}`
        } else if (currentPath.indexOf('/customize/game') > -1) {
            pageTitle = `${t('PAGE_TITLE_CUSTOMIZE_GAME_C')}`
        } else if (currentPath.indexOf('/bonus') > -1) {
            pageTitle = `${t('PAGE_TITLE_BONUS_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/rebate') > -1) {
            pageTitle = `${t('PAGE_TITLE_REBATE_SETTINGS_C')}`
        } else if (currentPath.indexOf('/settings/commission') > -1) {
            pageTitle = `${t('PAGE_TITLE_COMMISSION_SETTINGS_C')}`
        } else if (currentPath.indexOf('/commission') > -1) {
            pageTitle = `${t('PAGE_TITLE_COMMISSION_PAYOUT_C')}`
        } else if (currentPath.indexOf('/rebate') > -1) {
            pageTitle = `${t('PAGE_TITLE_REBATE_PAYOUT_C')}`
        } else if (currentPath.indexOf('/spinAndWin') > -1) {
            pageTitle = `${t('PAGE_TITLE_SPIN_WIN_C')}`
        } else if (currentPath.indexOf('/export') > -1) {
            pageTitle = `${t('PAGE_TITLE_EXPORT_C')}`
        } else {
            pageTitle = null
        }

        if (pageTitle) {
            return (
                <div style={{ marginLeft: '10px', fontSize: '14px' }}>
                    <b>{pageTitle}</b>
                </div>
            )
        } else {
            return null
        }
    }

    //Drawer Content
    const list = () => {
        let companyName = companyInfo?.name ?? ''
        let currencyCode = companyInfo?.currencyCode ?? ''

        return (
            <Box sx={{ width: 250, borderWidth: 0 }} style={{ backgroundColor: Constants.defaultDrawerHeaderColor }} role="presentation">
                <Box style={{ backgroundColor: Constants.defaultDrawerHeaderColor, color: 'white', padding: '20px', fontSize: '14px' }} >
                    <b>{`${companyName} (${currencyCode})`}</b>
                </Box>
                {sideMenu.map((menu, index) => {
                    let title = menu['title']
                    let icon = menu['icon']
                    let excludeFromRoles = menu['excludeFromRoles']
                    let type = menu['type']
                    let isExpandable = menu['isExpandable'] ?? false;
                    let isExpanded = menu['isExpanded'] ?? false;
                    let expandedItems = menu['expandedItems']

                    //Hide if this menu is excluded on current role
                    if (excludeFromRoles?.indexOf(userInfo?.role?.roleType) > -1) {
                        return null
                    } else if (type === MenuType.export) {
                        //Check if is allowed
                        if (!userInfo?.isAllowedReportExports) {
                            return null
                        }
                    }

                    const getListItem = (title, icon, type) => {
                        if (type === MenuType.darkMode) {
                            return (
                                <ListItem key={t(title)} disablePadding={true} dense={true} divider={false} style={{ backgroundColor: '#2f3539', color: 'white' }}>
                                    {getDarkModeToggle({ sideMenuMode: true })}
                                </ListItem>
                            )
                        }

                        return (
                            <ListItem key={t(title)} disablePadding={true} dense={true} divider={false} style={{ backgroundColor: '#2f3539', color: 'white' }} onClick={() => {
                                setDrawerOpen(false)

                                switch (type) {
                                    case MenuType.dashboard:
                                        //this.navigateToDashboard()
                                        break
                                    case MenuType.liveChat:
                                        goToLiveChat()
                                        break
                                    case MenuType.cashInOut:
                                        goToBankTransaction()
                                        break
                                    case MenuType.customerList:
                                        goToCustomerList()
                                        break
                                    case MenuType.commissionPay:
                                        navigate('/commission')
                                        break
                                    case MenuType.rebatePay:
                                        navigate('/rebate')
                                        break
                                    case MenuType.bonusSet:
                                        navigate('/bonus')
                                        break
                                    case MenuType.spinAndWinSet:
                                        navigate('/spinAndWin')
                                        break
                                    case MenuType.rebateSet:
                                        navigate('/settings/rebate')
                                        break
                                    case MenuType.commissionSet:
                                        navigate('/settings/commission')
                                        break
                                    case MenuType.depositWithdrawReport:
                                        navigate('/report/depositWithdraw')
                                        break
                                    case MenuType.bankReport:
                                        navigate('/report/bank')
                                        break
                                    case MenuType.bonusReport:
                                        navigate('/report/bonus')
                                        break
                                    case MenuType.rebateReport:
                                        navigate('/report/rebate')
                                        break
                                    case MenuType.commissionReport:
                                        navigate('/report/commission')
                                        break
                                    case MenuType.manualForfeitReport:
                                        navigate('/report/manualForfeit')
                                        break
                                    case MenuType.gameReport:
                                        navigate('/report/game')
                                        break
                                    case MenuType.customerReport:
                                        navigate('/report/customer')
                                        break
                                    case MenuType.staffReport:
                                        navigate('/report/staff')
                                        break
                                    case MenuType.transactionsSettings:
                                        navigate('/settings/transaction')
                                        break
                                    case MenuType.bankAccountSettings:
                                        navigate('/settings/bank')
                                        break
                                    case MenuType.paymentGatewaySettings:
                                        navigate('/settings/paymentGateway')
                                        break
                                    case MenuType.contactUsSettings:
                                        navigate('/settings/contact')
                                        break
                                    case MenuType.memberSettings:
                                        navigate('/settings/member')
                                        break
                                    case MenuType.phoneCodesSettings:
                                        navigate('/settings/phoneCode')
                                        break
                                    case MenuType.whatsAppBotsSettings:
                                        navigate('/settings/whatsapp')
                                        break
                                    case MenuType.botSettings:
                                        navigate('/settings/bots')
                                        break
                                    case MenuType.smsSettings:
                                        navigate('/settings/sms')
                                        break
                                    case MenuType.domainsSettings:
                                        navigate('/settings/domain')
                                        break
                                    case MenuType.usersRoleSettings:
                                        navigate('/settings/user')
                                        break
                                    case MenuType.languageSettings:
                                        navigate('/settings/language')
                                        break
                                    case MenuType.chatTemplates:
                                        navigate('/settings/chatTemplates')
                                        break
                                    case MenuType.themesCustomize:
                                        navigate('/customize/theme')
                                        break
                                    case MenuType.displayCustomize:
                                        navigate('/customize/display')
                                        break
                                    case MenuType.bannerCustomize:
                                        navigate('/customize/banner')
                                        break
                                    case MenuType.gameCategoryCustomize:
                                        navigate('/customize/gameCategory')
                                        break
                                    case MenuType.gameCustomize:
                                        navigate('/customize/game')
                                        break
                                    case MenuType.changePassword:
                                        goToChangePassword()
                                        break
                                    case MenuType.logout:
                                        performLogout()
                                        break;
                                    case MenuType.export:
                                        navigate('/export')
                                        break;
                                    default:
                                        break;
                                }
                            }}>
                                <ListItemButton style={{ color: 'white' }}>
                                    <ListItemIcon color="inherit" style={{ color: 'white' }}>
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText primary={t(title)} style={{ color: 'white' }} />
                                </ListItemButton>
                            </ListItem>
                        )
                    }

                    if (isExpandable) {
                        return (
                            (
                                <Accordion key={`expandableView${index}`} disableGutters={true} square={true} expanded={isExpanded} onChange={(event, expanded) => {
                                    setSideMenu(sideMenu => {
                                        return sideMenu.map((e, i) => {
                                            var tempItem = e
                                            if (i === index) {
                                                tempItem['isExpanded'] = !isExpanded
                                            }

                                            return tempItem
                                        })
                                    })
                                }} sx={{ backgroundColor: 'transparent' }} >
                                    <AccordionSummary style={{ backgroundColor: '#474c50', minHeight: '35px' }} expandIcon={<ExpandMore htmlColor='white' />} >
                                        <Typography fontSize={14} fontStyle={{ color: 'white' }} >{t(title)}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ margin: 0, padding: 0 }} >
                                        <List disablePadding={true}>
                                            {expandedItems.map((e) => {
                                                let title = e['title']
                                                let icon = e['icon']
                                                let type = e['type']
                                                let excludeFromRoles = e['excludeFromRoles']

                                                //Hide if this menu is excluded on current role
                                                if (excludeFromRoles?.indexOf(userInfo?.role?.roleType) > -1) {
                                                    return null
                                                }

                                                return getListItem(title, icon, type)
                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        )
                    } else {
                        return getListItem(title, icon, type)
                    }
                })}
            </Box>
        )
    }

    //COMPONENT METHOD
    const getDarkModeToggle = ({ sideMenuMode = false }) => {
        let selectedColor = sideMenuMode ? '#252525' : Utils.shared.getColorByMode({ lightColor: null, darkColor: '#252525' })
        let color = sideMenuMode ? '#505050' : Utils.shared.getColorByMode({ lightColor: null, darkColor: '#505050' })

        return (
            <Stack direction='row' spacing={sideMenuMode ? '28px' : '5px'} sx={{
                alignItems: 'center',
                fontSize: '12px',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingTop: sideMenuMode ? '8px' : null
            }}>
                <DarkMode />
                <ToggleButtonGroup
                    value={darkMode}
                    exclusive
                    size="small"
                    sx={{
                        color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' }),
                        backgroundColor: color,
                        '&.Mui-selected': {
                            color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' }),
                        }
                    }}
                    onChange={(e, value) => {
                        Utils.shared.storeLocalStorage(keys_dark_mode, value)
                        setDarkMode(value)
                        window.location.reload()
                    }}
                >
                    <ToggleButton size="small" value="auto" sx={{ color: 'inherit', '&.Mui-selected': { color: 'inherit', backgroundColor: selectedColor } }}>{`${t('MENU_AUTO_C')}`}</ToggleButton>
                    <ToggleButton size="small" value="on" sx={{ color: 'inherit', '&.Mui-selected': { color: 'inherit', backgroundColor: selectedColor } }}>{`${t('MENU_ON_C')}`}</ToggleButton>
                    <ToggleButton size="small" value="off" sx={{ color: 'inherit', '&.Mui-selected': { color: 'inherit', backgroundColor: selectedColor } }}>{`${t('MENU_OFF_C')}`}</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        )
    }
    const getQuickButton = ({
        title,
        icon,
        edge,
        path,
        onClick
    }) => {
        let isSelected = window.location.pathname.startsWith(path)

        return (
            <Tooltip title={title}>
                <IconButton
                    size="medium"
                    color="inherit"
                    edge={edge}
                    onClick={onClick}
                    sx={{
                        flexGrow: screenType.isMobile ? 1 : 0,
                        borderRadius: 0,
                        padding: 0,
                        height: '48px',
                        minWidth: '48px',
                        ":hover": {
                            backgroundColor: Constants.darkBackgroundColor
                        },
                        backgroundColor: isSelected ? Constants.darkBackgroundColor : null
                    }}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        )
    }

    //NAVIGATION METHODS
    const goToLiveChat = () => {
        setShowNewMessageDot(false)
        navigate('/liveChat')
    }

    const goToBankTransaction = () => {
        navigate('/bankTransaction')
    }

    const goToCustomerList = () => {
        navigate('/customers')
    }

    const goToChangePassword = () => {
        navigate('/profile/changePassword')
    }

    const goToWinLossReport = () => {
        navigate('/report/winLoss')
    }

    const performLogout = () => {
        setAlertDialog(alertDialog => ({
            ...alertDialog,
            title: `${t('GLOBAL_LOGOUT_C')}`,
            description: `${t('GLOBAL_ARE_YOU_SURE')}?`,
            isOpen: true,
            isConfirmation: true,
            onConfirmed: () => {
                FeathersClient.logout(() => {
                    setUserInfo(null)

                    navigate('/login', { replace: true })
                })
            }
        }))
    }

    //SOCKET METHOD
    const newMessageListener = () => {
        //Check role first
        let role = userInfo?.role?.roleType
        if (role === Constants.roleBOAgent) {
            //Do nothing
        } else if (!userInfo) {
            //Havent login
        } else {
            FeathersClient.listen('messages', 'created', 'topBar', (data) => {
                //Check if user is logged in or public user      
                if ((data?.company?._id === userInfo?.company?._id) &&
                    ((data?.user?.role?.roleType === Constants.rolePlayer) || (data?.deviceId !== null)) &&
                    data?.isRead !== true) {
                    new Audio(newMessageTone).play()

                    //Only add red dot if user is not in live chat page
                    if (window.location.pathname.indexOf('/liveChat') < 0) {
                        setShowNewMessageDot(true)
                    }
                }
            })

            //Hide red dot if current path is live chat
            if (window.location.pathname.indexOf('/liveChat') > -1) {
                setShowNewMessageDot(false)
            }
        }
    }

    //USE EFFECT
    useEffect(() => {
        //Set i18n language whenever language is changed
        i18n.changeLanguage(languageCode)
        Utils.shared.storeLocalStorage(keys_language_code, languageCode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCode])

    useEffect(() => {
        newMessageListener()
    }, [])

    useEffect(() => {
        //Check role first
        let role = userInfo?.role?.roleType
        if (role === Constants.roleBOAgent) {
            //Do nothing
        } else if (!userInfo) {
            //Havent login
        } else {
            let newData = data ?? []
            if (newData.length > 0) {
                setShowNewTransactionDot(true)
                newTransactionPlayer.loop = true
                newTransactionPlayer.play()
            } else {
                setShowNewTransactionDot(false)
                newTransactionPlayer.pause()
            }
        }
    }, [data])

    if (userInfo) {
        let roleType = userInfo?.role?.roleType

        return (
            <AppBar position="fixed" style={{ backgroundColor: Utils.shared.getColorByMode({ lightColor: Constants.defaultTopBarColor, darkColor: 'black' }) }} >
                <Toolbar variant='dense' sx={{ color: 'white', paddingLeft: 0, paddingRight: 0 }} >
                    {getQuickButton({
                        title: `${t('MENU_MORE_MENU')}`,
                        onClick: toggleDrawer(true),
                        edge: 'start',
                        icon: <MenuIcon />
                    })}
                    {[Constants.roleBOAgent].indexOf(roleType) > -1 ? null : getQuickButton({
                        title: `${t('MENU_LIVE_CHAT')}`,
                        onClick: () => {
                            goToLiveChat()
                        },
                        path: '/liveChat',
                        icon: <Badge badgeContent={1} invisible={showNewMessageDot ? false : true} variant='dot' color="error">
                            <Chat />
                        </Badge>
                    })}
                    {[Constants.roleBOCS, Constants.roleBOAgent].indexOf(roleType) > -1 ? null : getQuickButton({
                        title: `${t('MENU_BANK_TRANSACTIONS')}`,
                        onClick: () => {
                            goToBankTransaction()
                        },
                        path: '/bankTransaction',
                        icon: <Badge badgeContent={1} invisible={showNewTransactionDot ? false : true} variant='dot' color="error">
                            <Payment />
                        </Badge>
                    })}
                    {[Constants.roleBOAgent].indexOf(roleType) > -1 ? null : getQuickButton({
                        title: `${t('MENU_CUSTOMER_LIST')}`,
                        onClick: () => {
                            goToCustomerList()
                        },
                        path: '/customers',
                        icon: <PeopleOutline />
                    })}
                    {[Constants.roleBOCS, Constants.roleBOAgent].indexOf(roleType) > -1 ? null : getQuickButton({
                        title: `${t('MENU_WIN_LOSS_REPORT')}`,
                        onClick: () => {
                            goToWinLossReport()
                        },
                        path: '/report/winLoss',
                        icon: <ReceiptOutlined />
                    })}
                    {
                        screenType.isMobile ? null : (
                            <Box sx={{ flexGrow: 1 }} />
                        )
                    }
                    <Box sx={{ display: { xs: 'none', md: 'flex', gap: 16 } }}>
                        <Button sx={{
                            backgroundColor: 'transparent',
                            margin: 0,
                            padding: 0,
                            color: 'white',
                            textTransform: 'none',
                        }} endIcon={<ArrowDropDown htmlColor='white' />} onClick={handleClick} >
                            {userInfo.fullName ?? userInfo.userId ?? userInfo.userName ?? userInfo._id}
                        </Button>
                        <Button sx={{
                            backgroundColor: 'transparent',
                            margin: 0,
                            padding: 0,
                            textTransform: 'none',
                            minWidth: '0px'
                        }} onClick={handleClick2} >
                            <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
                                <img src={`${process.env.PUBLIC_URL}/images/flags/${selectedLanguage.icon}`} alt='language' height='24px' width='auto' loading="lazy" />
                                {
                                    (screenType.isMobile) ? null : (
                                        <Typography style={{ color: 'white', fontSize: '14px', paddingLeft: '4px' }}>
                                            {selectedLanguage.title}
                                        </Typography>
                                    )
                                }
                                <ExpandMore htmlColor={'white'} fontSize="small" />
                            </Stack>
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={isSettingsMenuOpen}
                            onClose={handleClose}
                        >
                            {getDarkModeToggle({ sideMenuMode: false })}
                            <Divider sx={{ margin: '8px 0px' }} />
                            <MenuItem onClick={() => {
                                handleClose()

                                goToChangePassword()
                            }} dense={true}>
                                <Stack direction='row' spacing='5px' sx={{ alignItems: 'center', fontSize: '12px' }}>
                                    <Security fontSize="small" />
                                    <div>{`${t('MENU_CHANGE_PASSWORD')}`}</div>
                                </Stack>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => {
                                handleClose()

                                performLogout()
                            }} dense={true}>
                                <Stack direction='row' spacing='5px' sx={{ alignItems: 'center', fontSize: '12px' }}>
                                    <Logout fontSize="small" />
                                    <div>{`${t('MENU_LOGOUT')}`}</div>
                                </Stack>
                            </MenuItem>
                        </Menu>
                        <Menu
                            anchorEl={anchorEl2}
                            open={isSettingsMenuOpen2}
                            onClose={handleClose2}
                            onClick={() => {
                                setAnchorEl2(null)
                            }}
                        >
                            {getLanguageMenu()}
                        </Menu>
                    </Box>
                </Toolbar>
                <Box sx={{ height: '20px', backgroundColor: Constants.darkBackgroundColor }}>
                    {getPageTitle()}
                </Box>
                <SwipeableDrawer
                    anchor='left'
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    PaperProps={{
                        style: {
                            backgroundColor: Constants.defaultDrawerHeaderColor
                        }
                    }}
                >
                    {list()}
                </SwipeableDrawer>
            </AppBar>
        )
    } else {
        return null
    }
}

export default AppTopBar