import { Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import { StyledTableCell, StyledTablePagination, StyledTableRow } from "../../components/dataGrid/tableGrid"
import { useTranslation } from "react-i18next";

export default function CustomerBalanceLog({ user }) {
    //Pagination Constants
    const rowsPerPage = 20

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { t } = useTranslation()

    //States
    const [page, setPage] = useState(0)
    const [list, setList] = useState([])

    //Ref
    const listRef = useRef(list)

    //DATA GRID METHODS
    const getTotal = () => {
        return list?.length ?? 0
    }
    const getRows = () => {
        return list?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((e, index) => {
            let createdAt = e?.createdAt ? Utils.shared.convertDateToString(Date.parse(e?.createdAt), Constants.date_format_2) : ''
            let setBalance = Utils.shared.getAmountString(e?.setBalance ?? 0)
            let beforeBalance = Utils.shared.getAmountString(e?.beforeBalance ?? 0)
            let afterBalance = Utils.shared.getAmountString(e?.afterBalance ?? 0)
            let action = e?.action ?? '-'
            let setBalanceType = e?.setBalanceType ?? 1
            let setBalanceTypeSymbol = setBalanceType === 1 ? '+' : '-'
            let backgroundColor = setBalanceType === 1 ? '#388E3C' : '#f54242'

            return (
                <StyledTableRow key={`row${index}`} hover={true} color={Utils.shared.getColorByMode({ lightColor: null, darkColor: '#757575' })}>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{createdAt}</StyledTableCell>
                    <StyledTableCell sx={{ backgroundColor: backgroundColor, color: 'white' }}>{`${setBalanceTypeSymbol}${setBalance}`}</StyledTableCell>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{beforeBalance}</StyledTableCell>
                    <StyledTableCell color={Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}>{afterBalance}</StyledTableCell>
                    <StyledTableCell>{action}</StyledTableCell>
                </StyledTableRow>
            )
        }) ?? []
    }

    //API METHODS
    const getList = () => {
        FeathersClient.create('get-player-balance-logs', {
            'company': userInfo?.company?._id,
            'user': user._id,
        }, null, setAlertDialog, (response) => {
            let balanceLogs = response?.balanceLogs ?? []
            setList([...[], ...balanceLogs])
            listRef.current = [...[], ...balanceLogs]

            setPage(0)
        })
    }

    //SOCKET METHODS
    const startSocket = () => {
        FeathersClient.listen('balance-logs', 'created', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index === -1 &&
                (data?.company?._id === userInfo?.company?._id) &&
                (data?.user?._id === user?._id)) {
                setList([data, ...listRef.current])
                listRef.current = [data, ...listRef.current]
            }
        })

        FeathersClient.listen('balance-logs', 'patched', 'customer', (data) => {
            let index = listRef.current.findIndex((e) => e._id === data._id)
            if (index > -1) {
                let tempList = [...listRef.current]
                tempList[index] = data
                listRef.current = tempList
                setList(tempList)
            }
        })

        FeathersClient.listen('balance-logs', 'removed', 'customer', (data) => {
            removeRecordIfExist(data)
        })
    }

    //FILTERING METHODS
    const removeRecordIfExist = (data) => {
        let tempList = [...listRef.current]
        let index = tempList.findIndex((e) => e._id === data._id)
        if (index > -1) {
            tempList.splice(index, 1)
            setList(tempList)
            listRef.current = tempList
        }
    }

    useEffect(() => {
        if (user) {
            getList(0, true)
            startSocket()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{`${t('GLOBAL_CREATED_DATE_TIME')}`}</StyledTableCell>
                                        <StyledTableCell>{`${t('GLOBAL_SET_BALANCE')}`}</StyledTableCell>
                                        <StyledTableCell>{`${t('GLOBAL_BEFORE_BALANCE')}`}</StyledTableCell>
                                        <StyledTableCell>{`${t('GLOBAL_AFTER_BALANCE')}`}</StyledTableCell>
                                        <StyledTableCell>{`${t('GLOBAL_ACTION')}`}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(list?.length ?? 0) > 0 ? getRows() : <StyledTableRow key='norecordrow'>
                                        <StyledTableCell component="th" scope="row" align="center" colSpan={5}>
                                            {`${t('GLOBAL_NO_RECORD_FOUND')}`}
                                        </StyledTableCell>
                                    </StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StyledTablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            count={getTotal()}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            page={page}
                            onPageChange={(event, newPage) => {
                                setPage(newPage)
                            }}
                            sx={{
                                backgroundColor: Utils.shared.getColorByMode({ lightColor: null, darkColor: '#2f3539' }),
                                color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
                                svg: {
                                    color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })
                                }
                            }}
                        />
                    </Paper>
                </Stack>
            </div>
        )
    } else {
        return null
    }
}