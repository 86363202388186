import { Box, Stack } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import BaseButton from "../../components/button/button"
import Dropdown from "../../components/dropdown/dropdown"
import FeathersClient from '../../feathers/feathersClient'
import InputTextField from "../../components/textfield/inputTextField"
import { useSnackbar } from '../../components/snackBar/baseSnackBar'
import Utils from "../../utils/utils"
import useScreenType from "react-screentype-hook"
import { useTranslation } from "react-i18next";

export default function CustomerSettings({ user }) {

    //Context
    const { setAlertDialog } = useContext(AlertDialogContext)
    const snackBar = useSnackbar()
    const { userInfo } = useContext(UserInfoContext)
    const screenType = useScreenType()
    const { t } = useTranslation()

    //States
    const [values, setValues] = useState({
        name: null,
        password: null,
        nameError: '',
        contactNumber: '',
        contactNumberError: '',
        affiliateUserId: '',
        affiliateCommissionRate: null,
        affiliateCommissionWithdrawalRate: null,
        maxWithdrawal: null,
        withdrawalFee: null,
        playerTurnover: null,
        playerRollover: null,
        playerDepositRollover: null,
        status: '',
    })
    const [result, setResult] = useState(null)
    const viewMobileNumber = userInfo?.viewMobileNumber ?? true

    //API METHODS
    const getPlayerSettings = () => {
        FeathersClient.create('get-player-settings', {
            user: user._id,
        }, null, setAlertDialog, (response) => {
            setResult(response)

            let contactNumber = viewMobileNumber ? response?.user?.contactNumber : Utils.shared.getMaskedPhoneNumber(response?.user?.contactNumber ?? '')

            setValues(values => ({
                ...values,
                name: response?.user?.fullName,
                password: null,
                contactNumber: contactNumber,
                affiliateUserId: response?.user?.affiliateUserId,
                affiliateCommissionRate: response?.user?.affiliateCommissionRate,
                affiliateCommissionWithdrawalRate: response?.user?.affiliateCommissionWithdrawalRate,
                maxWithdrawal: response?.user?.maxWithdrawal,
                withdrawalFee: response?.user?.withdrawalFee,
                playerTurnover: response?.user?.playerTurnover,
                playerRollover: response?.user?.playerRollover,
                playerDepositRollover: response?.user?.playerDepositRollover,
                status: response?.user?.status,
            }))
        })
    }

    //Component Methods
    const getTitleRow = (title, onTap) => {
        return (
            <Box key={title} sx={{ padding: '5px' }} style={{ backgroundColor: Utils.shared.getColorByMode({lightColor: '#3c4e5c', darkColor: '#252525'}), fontSize: '12px', color: 'white' }}>
                <Stack direction={'row'} spacing='10px' sx={{ alignItems: 'center' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        {title}
                    </Box>
                    <BaseButton title={`${t('GLOBAL_SAVE')}`} fullWidth={false} onTap={() => {
                        onTap()
                    }} />
                </Stack>
            </Box>
        )
    }
    const getAutoSizeTwoFieldRow = (fields) => {
        return (
            <Stack direction={screenType.isMobile ? 'column' : 'row'} spacing={'10px'} >
                {fields}
            </Stack>
        )
    }

    useEffect(() => {
        let mounted = true

        if (mounted) {
            if (user) {
                getPlayerSettings()
            }
        }

        return function cleanup() {
            mounted = false
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack>
                    {getTitleRow(`${t('GLOBAL_CUSTOMER_DETAILS_C')}`, () => {
                        if (!values.contactNumber) {
                            setValues(values => ({ ...values, contactNumberError: `${t('GLOBAL_MOBILE_NUMBER_IS_MANDATORY')}` }))
                            return
                        }

                        var data = {
                            'company': user?.company?._id,
                            'status': values.status,
                        }

                        //Update password if passed
                        if (values?.password) {
                            data['password'] = values?.password
                        }

                        if (values.name) {
                            data['fullName'] = values.name
                        }

                        if (viewMobileNumber) {
                            data['contactNumber'] = values?.contactNumber
                        }

                        FeathersClient.patch('users', user?._id, data, null, setAlertDialog, (response) => {
                            getPlayerSettings()
                            snackBar.showMessage(`${t('GLOBAL_CUSTOMER_DETAILS_SAVED_SUCCESSFULLY')}`)
                        })
                    })}
                    <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                        {getAutoSizeTwoFieldRow(
                            <>
                                <InputTextField title={`${t('GLOBAL_NAME')}`} placeholder={`${t('GLOBAL_ENTER_NAME')}`} value={values.name} errorText={values.nameError} showError={values.nameError.length > 0} onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        name: value,
                                        nameError: '',
                                    }))
                                }} />
                                <InputTextField title={`${t('GLOBAL_PASSWORD')}`} placeholder={`${t('GLOBAL_ENTER_PASSWORD')}`} value={values?.password} onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        password: value,
                                    }))
                                }} />
                            </>
                        )}
                        {getAutoSizeTwoFieldRow(
                            <>
                                <InputTextField title={`${t('GLOBAL_MOBILE_NUMBER')}`} placeholder={`${t('GLOBAL_ENTER_MOBILE_NUMBER')}`} value={values.contactNumber} errorText={values.contactNumberError} showError={values.contactNumberError.length > 0} onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        contactNumber: value,
                                        contactNumberError: '',
                                    }))
                                }} disabled={viewMobileNumber === false} />
                                <Dropdown title={`${t('GLOBAL_STATUS')}`} value={values.status} placeholder={`${t('GLOBAL_PLEASE_SELECT')}`} items={result?.userStatus} onSelectChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        status: value,
                                    }))
                                }} />
                            </>
                        )}
                    </Stack>
                    {getTitleRow(`${t('GLOBAL_AFFILIATE_C')}`, () => {
                        var data = {
                            'company': user?.company?._id,
                            'affiliateUserId': values.affiliateUserId,
                            'affiliateCommissionRate': values.affiliateCommissionRate,
                            'affiliateCommissionWithdrawalRate': values.affiliateCommissionWithdrawalRate,
                        }

                        if (!values.affiliateUserId) {
                            data['affiliateUser'] = null
                        }

                        FeathersClient.patch('users', user?._id, data, null, setAlertDialog, (response) => {
                            getPlayerSettings()
                            snackBar.showMessage(`${t('GLOBAL_CUSTOMER_AFFILIATE_SAVED_SUCCESSFULLY')}`)
                        })
                    })}
                    <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }}>
                        <InputTextField title={`${t('GLOBAL_AFFILIATE_CODE')}`} placeholder={`${t('GLOBAL_ENTER_AFFILIATE_CODE')}`} value={values.affiliateUserId} onTextChanged={(value) => {
                            setValues(values => ({
                                ...values,
                                affiliateUserId: value,
                            }))
                        }} />
                        {getAutoSizeTwoFieldRow(
                            <>
                                <InputTextField title={`${t('GLOBAL_AFFILIATE_COMMISSION_RATE')} (%)`} placeholder={`${t('GLOBAL_ENTER_AFFILIATE_COMMISSION_RATE')} (%)`} value={values.affiliateCommissionRate} type='number' onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        affiliateCommissionRate: value,
                                    }))
                                }} />
                                <InputTextField title={`${t('GLOBAL_AFFILIATE_COMMISSION_TURNOVER_ROLLOVER')}`} placeholder={`${t('GLOBAL_ENTER_AFFILIATE_COMMISSION_TURNOVER_ROLLOVER')}`} value={values.affiliateCommissionWithdrawalRate} type='number' onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        affiliateCommissionWithdrawalRate: value,
                                    }))
                                }} />
                            </>
                        )}
                    </Stack>
                    {getTitleRow(`${t('GLOBAL_WITHDRAWAL_C')}`, () => {
                        let data = {
                            'company': user?.company?._id,
                            'maxWithdrawal': values.maxWithdrawal,
                            'withdrawalFee': values.withdrawalFee,
                        }

                        FeathersClient.patch('users', user?._id, data, null, setAlertDialog, (response) => {
                            getPlayerSettings()
                            snackBar.showMessage(`${t('GLOBAL_CUSTOMER_WITHDRAWAL_SAVED_SUCCESSFULLY')}`)
                        })
                    })}
                    <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                        {getAutoSizeTwoFieldRow(
                            <>
                                <InputTextField title={`${t('GLOBAL_MAX_WITHDRAWAL')}`} placeholder={`${t('GLOBAL_ENTER_MAX_WITHDRAWAL')}`} value={values.maxWithdrawal} type='number' onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        maxWithdrawal: value,
                                    }))
                                }} />
                                <InputTextField title={`${t('GLOBAL_WITHDRAWAL_FEE')} (%)`} placeholder={`${t('GLOBAL_ENTER_WITHDRAWAL_FEE')} (%)`} value={values.withdrawalFee} type='number' onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        withdrawalFee: value,
                                    }))
                                }} />
                            </>
                        )}
                    </Stack>
                    {getTitleRow(`${t('GLOBAL_ROLLOVER_TURNOVER_C')}`, () => {
                        let data = {
                            'company': user?.company?._id,
                            'playerTurnover': values.playerTurnover,
                            'playerRollover': values.playerRollover,
                            'playerDepositRollover': values.playerDepositRollover,
                        }

                        FeathersClient.patch('users', user?._id, data, null, setAlertDialog, (response) => {
                            getPlayerSettings()
                            snackBar.showMessage(`${t('GLOBAL_CUSTOMER_ROLLOVER_TURNOVER_SAVED_SUCCESSFULLY')}`)
                        })
                    })}
                    <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                        <InputTextField title={`${t('GLOBAL_TURNOVER')}`} placeholder={`${t('GLOBAL_ENTER_TURNOVER')}`} value={values.playerTurnover} type='number' onTextChanged={(value) => {
                            setValues(values => ({
                                ...values,
                                playerTurnover: value,
                            }))
                        }} />
                        {getAutoSizeTwoFieldRow(
                            <>
                                <InputTextField title={`${t('GLOBAL_ROLLOVER')}`} placeholder={`${t('GLOBAL_ENTER_ROLLOVER')}`} value={values.playerRollover} type='number' onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        playerRollover: value,
                                    }))
                                }} />
                                <InputTextField title={`${t('GLOBAL_DEPOSIT_ROLLOVER')}`} placeholder={`${t('GLOBAL_ENTER_DEPOSIT_ROLLOVER')}`} value={values.playerDepositRollover} type='number' onTextChanged={(value) => {
                                    setValues(values => ({
                                        ...values,
                                        playerDepositRollover: value,
                                    }))
                                }} />
                            </>
                        )}
                    </Stack>
                    <Box sx={{ height: '20px' }} />
                </Stack>
            </div>
        )
    } else {
        return null
    }
}