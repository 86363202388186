import { Stack } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { AlertDialogContext, UserInfoContext } from "../../App"
import BaseButton from "../../components/button/button"
import Dropdown from "../../components/dropdown/dropdown"
import Utils from "../../utils/utils"
import Constants from '../../constants'
import FeathersClient from '../../feathers/feathersClient'
import InputTextField from "../../components/textfield/inputTextField"
import { Box } from "@mui/system"
import { useSnackbar } from '../../components/snackBar/baseSnackBar'
import { useTranslation } from "react-i18next";

export default function CustomerCredit({ user }) {
    const styles = {
        gridLayout: {
            width: 'calc(100% - 20px)',
            overflowWrap: 'break-word',
            backgroundColor: Utils.shared.getColorByMode({ lightColor: 'rgba(0, 0, 0, 0.1)', darkColor: '#424242' }),
            color: Utils.shared.getColorByMode({ lightColor: 'black', darkColor: 'white' }),
            padding: '10px',
            borderRadius: '3px'
        }
    }

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const snackBar = useSnackbar()
    const { t } = useTranslation()

    //States
    const [values, setValues] = useState({
        type: null,
        amount: '',
        amountError: '',
        isDebit: true,
        companyBank: null,
        remarks: '',
    })
    const [result, setResult] = useState(null)

    //API METHODS
    const getPlayerCredits = () => {
        FeathersClient.create('get-player-credits', {
            company: userInfo?.company?._id,
            user: user._id,
        }, null, setAlertDialog, (response) => {
            setResult(response)
        })
    }

    //COMPONENT METHODS
    function DetailRow({ title, value, fontSize = '12px' }) {
        return (
            <Stack direction='row' spacing='0px' sx={{ fontSize: fontSize, color: Utils.shared.getColorByMode({ lightColor: null, darkColor: 'white' })}}>
                <div>{`${title}:`}</div>
                <div><b>{value}</b></div>
            </Stack>
        )
    }

    const validateAmount = (amount) => {
        if (amount.length > 0) {
            let type = values.type
            switch (type) {
                case Constants.transactionTypeDeposit:
                case Constants.transactionTypeRebate:
                case Constants.transactionTypeCommission:
                    //Only allow + or empty
                    if (amount.startsWith('-')) {

                        return `${t('GLOBAL_NEGATIVE_AMOUNT_IS_NOT_ALLOWED_FOR_THIS_TRANSACTION_TYPE')}`
                    }
                    break
                case Constants.transactionTypeWithdrawal:
                case Constants.transactionTypeForfeited:
                case Constants.transactionTypeDepositFee:
                    //Only allow -
                    if (!amount.startsWith('-')) {
                        return `${t('GLOBAL_ONLY_NEGATIVE_AMOUNT_IS_ALLOWED_FOR_THIS_TRANSACTION_TYPE')}`
                    }
                    break;
                case Constants.transactionTypeBonus:
                case Constants.transactionTypeManual:
                    return null
                default:
                    return null
            }
        } else {
            return `${t('GLOBAL_AMOUNT_IS_MANDATORY')}`
        }
    }

    useEffect(() => {
        if (user) {
            getPlayerCredits()
        }
    }, [])

    if (user) {
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Stack direction='column' spacing='15px' sx={{ padding: '10px 10px 10px 10px' }} >
                    <Stack direction='row' spacing='10px' sx={{ alignItems: 'center' }}>
                        <DetailRow title={`${t('GLOBAL_CURRENT_CREDIT')}`} value={Utils.shared.getAmountString(user?.playerCredits ?? 0)} fontSize='16px' />
                        <BaseButton title={`${t('GLOBAL_WITHDRAW')}`} fullWidth={false} onTap={() => {
                            FeathersClient.create('get-player-games-balance', {
                                company: userInfo?.company?._id,
                                user: user?._id,
                                isBOWithdraw: true
                            }, null, setAlertDialog, (response) => {
                                getPlayerCredits()
                            })
                        }} />
                    </Stack>
                    <Box style={{ margin: '10px 10px 0px 0px', width: '100%' }}>
                        <div style={styles.gridLayout}>
                            {result?.gamesCredits?.map((e) => {
                                let gameName = e.label ?? ""
                                let credit = e.credits

                                return (
                                    <span style={{ fontSize: '12px' }}>
                                        {`${gameName}: `}
                                        <b>{credit}</b>
                                        {' '}
                                    </span>
                                )
                            })}
                        </div>
                    </Box>
                    <Dropdown title={`${t('GLOBAL_TYPE')}`} value={values.type ?? ''} placeholder={`${t('GLOBAL_FILTER_BY_TYPE')}`} items={result?.transactionTypes?.filter((e) => {
                        switch (e.key) {
                            case Constants.transactionTypeWithdrawal:
                                if ((user?.playerCredits ?? 0) <= 0) {
                                    return false
                                }
                                break
                            default:
                                break
                        }

                        return true
                    })} onSelectChanged={(value) => {
                        var amount = values.amount
                        switch (value) {
                            case Constants.transactionTypeDeposit:
                            case Constants.transactionTypeRebate:
                            case Constants.transactionTypeCommission:
                                //Only allow + or empty
                                if (amount.startsWith('-')) {
                                    amount = amount.replace('-', '')
                                }
                                break
                            case Constants.transactionTypeWithdrawal:
                            case Constants.transactionTypeForfeited:
                            case Constants.transactionTypeDepositFee:
                                //Only allow -
                                if (!amount.startsWith('-')) {
                                    amount = `-${amount}`
                                }
                                break;
                            case Constants.transactionTypeBonus:
                            case Constants.transactionTypeManual:
                                break
                            default:
                                break
                        }

                        setValues(values => ({
                            ...values,
                            type: value,
                            amount: amount,
                        }))
                    }} />
                    <InputTextField title={`${t('GLOBAL_AMOUNT')}`} placeholder={`${t('GLOBAL_ENTER_AMOUNT')}`} 
                    helperText={`${t('GLOBAL_ADD_OR_IN_FRONT_TO_INDICATE_DEBIT_OR_CREDIT_AMOUNT')}`} value={values.amount} disabled={!(values.type)} errorText={values.amountError} showError={values.amountError.length > 0} onTextChanged={(value) => {
                        var isDebit = true
                        if (value.startsWith('-')) {
                            isDebit = false
                        }

                        setValues(values => ({
                            ...values,
                            amount: value,
                            amountError: '',
                            isDebit: isDebit
                        }))
                    }} />
                    {((values.type === Constants.transactionTypeDeposit) || (values.type === Constants.transactionTypeWithdrawal)) ? (
                        <Dropdown title={`${t('GLOBAL_TRANSFER_BANK')}`} value={values.companyBank ?? ''} placeholder={`${t('GLOBAL_PLEASE_SELECT')}`} items={result?.companyBanks} onSelectChanged={(value) => {
                            setValues(values => ({
                                ...values,
                                companyBank: value,
                            }))
                        }} />
                    ) : null}
                    <InputTextField title={`${t('GLOBAL_REMARKS')}`} placeholder={`${t('GLOBAL_ENTER_REMARKS')}`} value={values.remarks} disabled={!(values.type)} onTextChanged={(value) => {
                        setValues(values => ({
                            ...values,
                            remarks: value,
                        }))
                    }} />
                    <BaseButton title={`${t('GLOBAL_CONFIRM')}`} onTap={() => {
                        let amount = values.amount.replace(/\-/g, '').replace(/\+/g, '')
                        let amountError = validateAmount(values.amount) ?? ''
                        if (amountError.length > 0) {
                            setValues(values => ({
                                ...values,
                                amountError: amountError,
                            }))
                            return
                        }

                        if (values.remarks.length === 0) {
                            return
                        }

                        if (!values.type) {
                            return
                        }

                        FeathersClient.create('update-player-credits', {
                            'company': userInfo?.company?._id,
                            'user': user?._id,
                            'contactNumber': user?.contactNumber,
                            'userId': user?.userId,
                            'type': values.type,
                            'amount': amount,
                            'amountType': values.isDebit ? 1 : 2,
                            'transferBank': values.companyBank,
                            'remarks': 2,
                            'remarksOther': values.remarks,
                        }, null, setAlertDialog, (response) => {
                            setValues(values => ({
                                ...values,
                                type: null,
                                amount: '',
                                amountError: '',
                                isDebit: true,
                                companyBank: null,
                                remarks: '',
                            }))

                            snackBar.showMessage(`${t('GLOBAL_TRANSACTION_ADDED_SUCCESSFULLY')}`)
                        })
                    }} />
                </Stack>
            </div>
        )
    } else {
        return null
    }
}