import { Box, Checkbox, FormControlLabel, IconButton, Stack, TextareaAutosize, Typography } from "@mui/material"
import { useContext, useState } from "react"
import useScreenType from "react-screentype-hook"
import { AlertDialogContext, UserInfoContext } from "../../../App"
import BaseButton from "../../../components/button/button"
import useSnackbar from "../../../components/snackBar/useSnackbar"
import Constants from '../../../constants'
import FeathersClient from '../../../feathers/feathersClient'
import SwitchField from "../../../components/switch/switchField"
import { TreeItem, TreeView } from "@mui/lab"
import { ChevronRight, ExpandMore, IndeterminateCheckBox } from "@mui/icons-material"
import { useTranslation } from "react-i18next";

export default function AddEditCustomCategory({ gameCategory, result, onClosed }) {
    const screenType = useScreenType()
    const snackBar = useSnackbar()
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { t } = useTranslation()

    const [category, setCategory] = useState(gameCategory)
    const [expanded, setExpanded] = useState(['999'])
    const companyGames = result?.companyGames ?? []
    const gameCategories = result?.gameCategories ?? []

    //Component Methods
    const getGameSelectionView = () => {
        const getTreeItem = (id, title, selected = false, isPartialSelected = false, children, onChecked) => {
            return (
                <TreeItem key={id} nodeId={`${id}`} label={
                    <FormControlLabel
                        checked={selected}
                        control={
                            <Checkbox indeterminate={isPartialSelected} indeterminateIcon={
                                (
                                    <IndeterminateCheckBox htmlColor={Constants.buttonGreenColor} />
                                )
                            } size="small" sx={{
                                margin: '0px 5px 2px 8px',
                                padding: 0,
                                '&.Mui-checked': {
                                    color: Constants.buttonGreenColor,
                                },
                                '&.Mui-focused': {
                                    color: 'transparent',
                                },
                            }} />
                        }
                        label={<div style={{ color: 'black', fontSize: '12px' }}>{title}</div>}
                        labelPlacement="end"
                        onChange={(e, checked) => {
                            onChecked(checked)
                        }}
                    />
                } sx={{
                    color: 'black',
                    fontSize: '12px',
                }} expandIcon={
                    <IconButton onClick={() => {
                        setExpanded(expanded => [...expanded, id])
                    }} sx={{ padding: '0px' }}>
                        <ChevronRight fontSize='small' />
                    </IconButton>
                } collapseIcon={
                    <IconButton onClick={() => {
                        let temp = expanded.filter((e) => e !== id)
                        setExpanded(temp)
                    }} sx={{ padding: '0px' }}>
                        <ExpandMore fontSize='small' />
                    </IconButton>
                } >
                    {children}
                </TreeItem>
            )
        }
        const getCategorizedCompanyGames = () => {
            var categoryCompanyGames = []

            //Loop all categories
            gameCategories?.forEach((category) => {
                let gameCategory = category.key
                let games = companyGames?.filter((e) => e.game?.gameCategory?.indexOf(gameCategory) > -1)

                //Loop all games in category to add into disabled
                games?.forEach((game) => {
                    categoryCompanyGames.push({
                        companyGame: game.id,
                        companyGameCategory: gameCategory,
                    })
                })
            })

            return categoryCompanyGames
        }
        const categoryitems = () => {
            return gameCategories?.map((e) => {
                const gameCategory = e?.key
                const games = result?.companyGames?.filter((game) => game?.game?.gameCategory?.indexOf(gameCategory) > -1)

                //Check if there is any game in this category
                if (games.length === 0) {
                    //No games, dont include
                    return null
                }

                let selectedGamesInCategory = games.filter((game) => {
                    //Check if any game under this category is disabled
                    let gameIndex = category?.companyGames?.findIndex((companyGame) => (game.id === companyGame?.companyGame && companyGame?.companyGameCategory === gameCategory)) ?? -1
                    if (gameIndex > -1) {
                        return true
                    }

                    return false
                })
                let isGameCategorySelected = selectedGamesInCategory.length === games?.length
                let isPartiallySelected = isGameCategorySelected ? false : selectedGamesInCategory.length > 0

                //Get games list
                let childs = games.map((game) => {
                    let id = `${gameCategory}${game?._id}`
                    let gameIndex = category?.companyGames?.findIndex((companyGame) => (game.id === companyGame?.companyGame && companyGame?.companyGameCategory === gameCategory)) ?? -1
                    let isGameSelected = gameIndex > -1
                    let label = game?.game?.label ?? ''
                    let vendor = game?.game?.vendor ?? ''

                    return getTreeItem(id, `${label} ${vendor.length > 0 ? `(${vendor})` : ''}`, isGameSelected, false, null, (checked) => {
                        let currentCompanyGames = category?.companyGames ?? []
                        var temp = [...currentCompanyGames]

                        if (checked) {
                            temp.push({
                                companyGame: game.id,
                                companyGameCategory: gameCategory,
                            })
                        } else {
                            let index = temp.findIndex((element) => ((element?.companyGame === game.id) && (element?.companyGameCategory === gameCategory)))
                            temp.splice(index, 1)
                        }

                        setCategory(category => ({
                            ...category,
                            companyGames: temp,
                        }))
                    })
                })

                return getTreeItem(JSON.stringify(gameCategory), e?.value, isGameCategorySelected, isPartiallySelected, childs, (checked) => {
                    var temp = [...category?.companyGames]

                    if (checked) {
                        //First remove all currently selected games for this category
                        temp = temp.filter((element) => ((element?.companyGameCategory !== gameCategory)))

                        //Then add all games from this category
                        games?.forEach((element) => {
                            temp.push({
                                companyGame: element.id,
                                companyGameCategory: gameCategory,
                            })
                        })
                    } else {
                        //Remove all currently selected games for this category
                        temp = temp.filter((element) => ((element?.companyGameCategory !== gameCategory)))
                    }

                    setCategory(category => ({
                        ...category,
                        companyGames: temp,
                    }))
                })
            })
        }
        const isAllGamesSelected = () => {
            let isGameCategorySelected = (category?.companyGames?.length === getCategorizedCompanyGames().length)

            return (category?.companyGames?.length === getCategorizedCompanyGames().length)
        }
        const isAllGamesPartiallySelected = () => {
            return isAllGamesSelected() ? false : category?.companyGames?.length > 0
        }

        return (
            <TreeView expanded={expanded} sx={{
                color: 'black',
                maxWidth: '98%',
                minHeight: '200px',
                maxHeight: '300px',
                overflowY: 'scroll',
                border: '1px solid black'
            }} disableSelection={true} >
                {getTreeItem('999', `${t('GLOBAL_ALL_C')}`, isAllGamesSelected(), isAllGamesPartiallySelected(), categoryitems(), (checked) => {
                    if (checked) {
                        setCategory(category => ({
                            ...category,
                            companyGames: getCategorizedCompanyGames(),
                        }))
                    } else {
                        setCategory(category => ({
                            ...category,
                            companyGames: null,
                        }))
                    }
                })}
            </TreeView>
        )
    }

    return (
        <Stack spacing='20px' sx={{ margin: '10px', minWidth: screenType.isMobile ? null : '400px' }}>
            <TextareaAutosize
                key='name'
                minRows={8}
                placeholder={`${t('GLOBAL_ENTER_CATEGORY_NAME_HERE_SUPPORT_HTML')}`}
                style={{ width: '100%' }}
                maxRows={20}
                value={category?.name ?? ''}
                onChange={(e) => {
                    setCategory(category => ({
                        ...category,
                        name: e.target.value,
                    }))
                }}
            />
            <Stack spacing={'5px'}>
                <Box>
                    <Typography sx={{ fontSize: '14px' }}>
                        {`${t('GLOBAL_SELECT_GAME_BY_CATEGORY_TO_BE_INCLUDED_IN_THIS_CUSTOM_CATEGORY')}`}
                    </Typography>
                </Box>
                {getGameSelectionView()}
                <Typography sx={{ fontSize: '14px' }}>
                    {`${t('GLOBAL_TOTAL_SELECTED_GAME')}: ${category?.companyGames?.length ?? 0}`}
                </Typography>
            </Stack>
            <SwitchField title={`${t('GLOBAL_ACTIVE')}`} value={category?.active ?? false} onChanged={(checked) => {
                setCategory(category => ({
                    ...category,
                    active: checked,
                }))
            }} />
            <Stack direction='row' spacing='10px'>
                <BaseButton title={`${t('GLOBAL_SAVE')}`} onTap={() => {
                    console.log(category?.companyGames)
                    let data = {
                        name: category?.name,
                        company: userInfo?.company?._id,
                        companyGames: category?.companyGames
                    }
                    let query = {
                        query: {
                            '$populate': 'company'
                        }
                    }

                    if (category?._id) {
                        //Edit
                        FeathersClient.patch('company-custom-games', category?._id, data, query, setAlertDialog, (response) => {
                            onClosed()
                            snackBar.showMessage(`${t('GLOBAL_CATEGORY_SAVED_SUCCESSFULLY')}`)
                        })
                    } else {
                        //New
                        FeathersClient.create('company-custom-games', data, query, setAlertDialog, (response) => {
                            onClosed(null)
                            snackBar.showMessage(`${t('GLOBAL_NEW_CATEGORY_ADDED_SUCCESSFULLY')}`)
                        })
                    }
                }} />
                <BaseButton title={`${t('GLOBAL_CANCEL')}`} color={Constants.defaultCancelButtonColor} onTap={() => {
                    onClosed(false)
                }} />
            </Stack>
        </Stack>
    )

}