import Constants, { languages, languageCodeEnglish, keys_dark_mode, keys_language_code } from '../constants'
import { format, parseISO } from "date-fns"
import TimeAgo from 'javascript-time-ago'
import imageCompression from 'browser-image-compression'
import en from 'javascript-time-ago/locale/en'
import FeathersClient from '../feathers/feathersClient'
TimeAgo.addDefaultLocale(en)

class Utils {
    static shared = new Utils()

    // Store values into local storage
    storeLocalStorage(key, value) {
        window.localStorage.setItem(key, value)
    }

    // Retrieve values into local storage
    retrieveLocalStorage(key) {
        return window.localStorage.getItem(key)
    }

    // Brighten color
    brighten(color, c) {
        const calc = (sub1, sub2) => Math.min(255, Math.floor(parseInt(color.substr(sub1, sub2), 16) * c)).toString(16).padStart(2, "0")
        return `#${calc(1, 2)}${calc(3, 2)}${calc(5, 2)}`
    }

    // Get light/dark mode color
    getColorByMode({ lightColor, darkColor }) {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        const cachedDarkMode = Utils.shared.retrieveLocalStorage(keys_dark_mode) ?? 'off'

        var isDarkMode = false
        if (cachedDarkMode === 'auto') {
            isDarkMode = darkModeMediaQuery.matches
        } else {
            isDarkMode = cachedDarkMode === 'on' ? true : false
        }

        if (isDarkMode) {
            return darkColor
        } else {
            return lightColor
        }
    }

    // Format to amount
    getAmountString(amount, currency = '') {
        const formatted = parseFloat(amount).toFixed(2)

        return `${currency}${formatted}`
    }

    // Format phone number
    getMaskedPhoneNumber(contactNumber = '') {
        let firstPart = contactNumber.length >= 4 ? contactNumber.substring(0, 2) : ''
        let secondPart = contactNumber.length >= 8 ? '******' : ''
        let lastPart = contactNumber.length >= 11 ? contactNumber.substring(8, 11) : ''
        let joined = `${firstPart}${secondPart}${lastPart}`

        return joined.length > 0 ? joined : '-'
    }

    // Trim spaces
    zeroWidthTrim(stringToTrim) {
        const ZERO_WIDTH_SPACES_REGEX = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g;
        const trimmedString = stringToTrim.replace(ZERO_WIDTH_SPACES_REGEX, '');
        return trimmedString;
    }

    // Truncate by length
    truncateString(str, length) {
        var dots = str.length > length ? '...' : '';
        return str.substring(0, length) + dots;
    }

    /* IMAGES METHODS */
    getBase64FromFile(file) {
        return new Promise(resolve => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        })
    }
    getMessagesImages(listing, fileName, roomId) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_messages}${roomId}/${fileName}`
    }
    getCompanyImagesLink(listing, company, fileName) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_companies}${company?._id}/${fileName}`
    }
    getCompanyGamesSequenceImagesLink(listing, fileName) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_company_games_sequence}${fileName}`
    }
    getGamesImagesLink(listing, fileName) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_games}${fileName}`
    }
    getCompanyCustomGamesImagesLink(listing, fileName) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_company_custom_games}${fileName}`
    }
    getBonusImagesLink(listing, company, fileName) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_bonus}${company?._id}/${fileName}`
    }
    getWhatsAppQRImagesLink(listing, company, fileName) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_whatsapp_bot_qr_image}${company?._id}/${fileName}`
    }
    getReportExportLink(listing, company, fileName) {
        return `${listing?.cloudFrontDomain}${Constants.bucket_path_reports}${company?._id}/${fileName}`
    }
    getCompressedFile(imageFile, setAlertDialog, onFileCompressed) {
        //Check if file is image
        if (imageFile.type.includes('image')) {
            //Image, perform compression
            var options = {
                useWebWorker: true,
                maxWidthOrHeight: 800,
                alwaysKeepResolution: true,
            }
            imageCompression(imageFile, options).then(function (compressedFile) {
                Utils.shared.getBase64FromFile(compressedFile).then((result) => {
                    onFileCompressed(result, imageFile)
                }).catch((error) => {
                    setAlertDialog(alertDialog => ({
                        ...alertDialog,
                        description: error.message,
                        isOpen: true,
                    }))
                })
            }).catch(function (error) {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    description: error.message,
                    isOpen: true,
                }))
            })
        } else {
            //Other file, no compression needed
            Utils.shared.getBase64FromFile(imageFile).then((result) => {
                onFileCompressed(result, imageFile)
            }).catch((error) => {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    description: error.message,
                    isOpen: true,
                }))
            })
        }
    }

    /* DEVICE METHODS */
    //Get device type
    checkIfIsMobile() {
        var isMobile = false
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4))) {
            isMobile = true
        }
        return isMobile
    }

    //Get current platform
    getCurrentPlatform() {
        const userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)) {
            return 'android';
        } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
            return 'ios';
        } else {
            return 'desktop'
        }
    }

    /* DATES */
    //Get date only
    toDateOnly(date) {
        var formatDate = new Date(date)
        return formatDate.setHours(0, 0, 0, 0)
    }

    //Get end of day
    toEndOfDay(date) {
        var formatDate = new Date(date)
        return formatDate.setHours(23, 59, 59)
    }

    //Get start of month
    toStartOfMonth(date) {
        let formatDate = new Date(date)
        let startOfMonth = new Date(formatDate.getFullYear(), formatDate.getMonth(), 1);
        return startOfMonth
    }

    //Get end of month
    toEndOfMonth(date) {
        let formatDate = new Date(date)
        let endOfMonth = new Date(formatDate.getFullYear(), formatDate.getMonth() + 1, 0);
        return endOfMonth
    }

    //Get number of week between date
    getWeeksDiff(startDate, endDate) {
        const msInWeek = 1000 * 60 * 60 * 24 * 7;

        return Math.round(Math.abs(endDate - startDate) / msInWeek);
    }

    //Convert date to string with format dd/mm/yyyy
    convertDateToString(date, formatString) {
        if (date) {
            if (typeof date === 'string') {
                return format(parseISO(date), formatString)
            } else {
                return format(date, formatString)
            }
        } else {
            return null
        }
    }

    //Get minutes and seconds string by seconds duration
    getTimerDisplay(duration) {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    //Get time ago display
    getTimeAgo(date) {
        let timeAgo = new TimeAgo('en-US')
        return timeAgo.format(date, 'mini-now')
    }
    /* ====================== */

    arraysEqual(a1, a2) {
        /* WARNING: arrays must not contain {objects} or behavior may be undefined */
        return JSON.stringify(a1) == JSON.stringify(a2);
    }

    //ROUTING METHODS
    //Get route after login by role type
    getRouteByRoleType(userInfo, navigate, customPath) {
        let roleType = userInfo?.role?.roleType
        let path = customPath ?? window.location.pathname

        if (path === '/' || path === '/liveChat' || path === '/login') {
            if ([Constants.roleBOAgent].indexOf(roleType) > -1) {
                navigate('/report/depositWithdraw', { replace: true })
            } else {
                navigate('/liveChat', { replace: true })
            }
        }
    }

    //LISTING METHODS
    getBackendListing(setAlertDialog, onRetrieved) {
        FeathersClient.create('get-listing', {}, null, setAlertDialog, (response) => {
            onRetrieved(response)
        })
    }

    //CHECKING METHODS
    supportsBSBNumber(userInfo) {
        const countryCode = userInfo?.company?.countryCode

        switch (countryCode) {
            case Constants.countryCodeAU:
                return true
            default:
                return false
        }
    }

    //Language
    getDefaultLanguage({ availableLanguages, isByPassAvailableChecking = false, i18n }) {
        const defaultLanguage = languageCodeEnglish;

        //Get initial default language
        let cachedLanguage = i18n.language ?? this.retrieveLocalStorage(keys_language_code)
        if (cachedLanguage && cachedLanguage.length > 0) {
            //Has cached language, check if language still available
            if (isByPassAvailableChecking) {
                return cachedLanguage
            } 
            else {
                let index = availableLanguages?.findIndex((e) => e === cachedLanguage) ?? -1
                if (index > -1) {
                    return cachedLanguage
                }
            }
        }

        //No cached language, select BO set default language
        return defaultLanguage ?? languages[0].code
    }
}

export default Utils