import { Stack } from "@mui/material"
import Dropdown from "../../../components/dropdown/dropdown"
import InputTextField from "../../../components/textfield/inputTextField"
import BaseButton from "../../../components/button/button"
import { useContext, useState } from "react"
import useScreenType from "react-screentype-hook"
import { AlertDialogContext, UserInfoContext } from "../../../App"
import useSnackbar from "../../../components/snackBar/useSnackbar"
import FeathersClient from '../../../feathers/feathersClient'
import Utils from "../../../utils/utils"
import { useTranslation } from "react-i18next";

export default function AddCustomerBankAccountFormPopup({ account, user, result, onClosed }) {
    const screenType = useScreenType()

    //Context
    const { userInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const snackBar = useSnackbar()
    const { t } = useTranslation()

    //States
    const [editAccount, setEditAccount] = useState(account)

    return (
        <Stack spacing='20px' sx={{ margin: '10px', minWidth: screenType.isMobile ? null : '400px' }}>
            <Dropdown title={`${t('GLOBAL_BANK_NAME')}`} value={editAccount?.withdrawalBank ?? ''} items={result?.withdrawalBanks} onSelectChanged={(value) => {
                setEditAccount(editAccount => ({
                    ...editAccount,
                    withdrawalBank: value
                }))
            }} />
            <InputTextField
                title={`${t('GLOBAL_BANK_ACCOUNT_NUMBER')}`}
                placeholder={`${t('GLOBAL_ENTER_BANK_ACCOUNT_NUMBER')}`}
                value={editAccount?.withdrawalBankAccountNumber}
                onTextChanged={(value) => {
                    setEditAccount(editAccount => ({
                        ...editAccount,
                        withdrawalBankAccountNumber: value
                    }))
                }} />
            {
                (Utils.shared.supportsBSBNumber(userInfo)) ?
                    (
                        <InputTextField
                            title={`${t('GLOBAL_BSB_NUMBER_OPTIONAL')}`}
                            placeholder={`${t('GLOBAL_ENTER_BSB_NUMBER')}`}
                            helperText={`${t('GLOBAL_FOR_CERTAIN_COUNTRY_USE_ONLY')}`}
                            value={editAccount?.bsbNumber}
                            onTextChanged={(value) => {
                                setEditAccount(editAccount => ({
                                    ...editAccount,
                                    bsbNumber: value
                                }))
                            }} />
                    ) : null
            }
            <BaseButton title={`${t('GLOBAL_SUBMIT')}`} onTap={() => {
                let data = {
                    company: userInfo?.company._id,
                    user: user._id,
                    withdrawalBank: editAccount?.withdrawalBank,
                    withdrawalBankAccountNumber: editAccount?.withdrawalBankAccountNumber,
                    bsbNumber: editAccount?.bsbNumber,
                }

                if (editAccount?._id) {
                    //Edit
                    FeathersClient.patch('player-withdrawal-accounts', editAccount._id, data, null, setAlertDialog, (response) => {
                        snackBar.showMessage(`${t('GLOBAL_BANK_ACCOUNT_UPDATED_SUCCESSFULLY')}`)
                        onClosed()
                    })
                } else {
                    //Add
                    FeathersClient.create('add-player-withdrawal-account', data, null, setAlertDialog, (response) => {
                        snackBar.showMessage(`${t('GLOBAL_BANK_ACCOUNT_ADDED_SUCCESSFULLY')}`)
                        onClosed()
                    })
                }
            }} />
        </Stack>
    )
}