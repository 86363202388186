import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BaseButton from "../components/button/button";
import { useTranslation } from "react-i18next";

export default function PageNotFound() {
    let navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor: 'rgba(225, 225, 225, 1.0)' }}>
            <Stack spacing='20px' sx={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <img src={`${process.env.PUBLIC_URL}/images/icons/404-error.png`} alt='404' height='100px' width='auto' />
                <h2>{`${t('GLOBAL_PAGE_NOT_FOUND')}`}</h2>
                <h7>{`${t('GLOBAL_THE_PAGE_YOU_ARE_LOOKING_FOR_DOES_NOT_EXIST')}`}</h7>
                <BaseButton title={`${t('GLOBAL_HOME_C')}`} fullWidth={false} onTap={() => {
                    navigate('/')
                }} />
            </Stack>
        </Stack>
    )
}